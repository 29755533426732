import { Component, OnInit,inject } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor() {
  }
   cardInfo1 = {
    "title" : "Gerüstbau",
    "subTitle": "Unterschiedlichste Gerüstvarianten",
    "image": "../assets/gerustbau/gerust5.jpg",
    "link" :"/gerustbau",
    "text" : "Wir erstellen Fassadengerüste für Malerarbeiten und Dachdeckerarbeiten. Sowie Treppentürme und fahrbare Rollengerüste. "
    };

  ngOnInit(): void {
  }

}
