
<div class="banner about-banner">
</div>

  <div class="container">

      <article>
        <h1>Über Uns</h1>
        <p>

          Die Firma Löffler wurde im Jahre 1950 von Ernst Löffler sen. gegründet.
          Damals noch mit einem "Wanderzirkus" ohne eigene Werkstatt.
        </p>

        <p>
          Ernst Löffler zog damals in dem aufstrebenden Dettenhausen einfach von Baustelle zu Baustelle.
          Der Strom für die Maschinen musste bei den Nachbarn erbettelt werden.
        </p>

        <p>
          1955 konnte eine erste Werkstatt auf dem heutigen Firmengelände eröffnet werden.
          Auch die Ehefrau und Bekannte mussten dem Firmengründer Ernst zur Hand gehen und mit anpacken!
          Bei Wind und Wetter musste gearbeitet werden.
        </p>

        <p>
          Dass die beiden Söhne Erwin und Ernst jun. dem Vater zur Hand gehen würden war schnell klar.
        </p>

        <p>
          Erwin begann 1951 seine Lehre und Ernst jun. stieg 1958 mit ein.
        </p>

        <p>
          Später führten sie gemeinsam das Unternehmen.
        </p>

        <p>
          Im Jahre 2000 erhielten sie die Ehrenurkunde der iZimmerer-Innung Tübingen zum 50-jährigen Jubiläum.
        </p>

         <div fxLayout="row" fxLayout.xs="column" fxFlexFill>
          <div class="uber-uns-fotos" fxFlex="33"
          fxFlex.sm="100"
          fxFlex.xs="100"
          fxLayout="column">
          <img class ="image" alt="Ernst Löffler sen." src="../assets/seniorcrop.png">
          <small> Der Firmengründer Ernst Löffler sen. </small>
         </div>

         <div  class="uber-uns-fotos" fxFlex="33"
         fxFlex.sm="100"
         fxFlex.xs="100"
         fxLayout="column">
         <img class ="image" alt="Marie Löffler" src="../assets/frauen.PNG">
         <small>Marie Löffler (links), Fr. Kraft aus Neuweiler (rechts)</small>
        </div>

        <div  class="uber-uns-fotos" fxFlex="33"
        fxFlex.sm="100"
        fxFlex.xs="100"
        fxLayout="column">
        <img class ="image" alt="50 jährigen Jubiläum" src="../assets/jubileum.PNG" >
        <small> v.l.: Erwin Löffler, Obermeister Thomas Schenk, Ehrenobermeister
          Thomas Schmid, Bürgermeister Hans-Joachim Raich, Ernst Löffler.</small>
       </div>

     </div>
           <p>
            Im Jahre 2008 übernahm der Sohn von Erwin Löffler, Jürgen Löffler, die Geschäftsführung der Firma Holzbau Löffler.
            Er hat den Betrieb weitergeführt unter dem Motto "Löffler Holzbau - Ihr Meisterbetrieb".
           </p>

           <p>
            Der Bereich Gerüstbau wurde bis dahin in Eigenregie von Jürgen Löffler geführt, mit der Übernahme der Firma von seinem Vater
            wurde dann die Firma Löffler Holz- und Gerüstbau GmbH Co. KG gegründet.
           </p>

           <p>
            Nach dem allzu frühen Tod von Jürgen Löffler hat die Geschäftsleitung seine Frau Elke Löffler übernommen.
           </p>
      </article>
    <hr>
    <article>
        <h2> Unsere Partner</h2>
        <br>
        <div class="content" fxLayout="row" fxLayout.xs="column" fxFlexFill>
            <div  class="partner"  fxFlex="50"
            fxFlex.md="33"
            fxFlex.sm="50"
            fxFlex.xs="100"
            fxLayout="column">
            <a href="https://www.kemmler.de/">
              <img src="../assets/kemmler-logo.png"  width="300px">
            </a>
            </div>
            <div class="partner"  fxFlex="50"
            fxFlex.md="100"
            fxFlex.sm="50"
            fxFlex.xs="100"
            fxLayout="column">
            <a href="https://www.wuerth.de/">
              <img src="../assets/wurth-logo.jpg" width="300px">
            </a>
            </div>

          </div>

          <div class="content" fxLayout="row" fxLayout.xs="column" fxFlexFill>




            <div class="content" fxLayout="row" fxLayout.xs="column" fxFlexFill>

              <div class="partner" fxFlex="50"
              fxFlex.md="33"
              fxFlex.sm="50"
              fxFlex.xs="100"
              fxLayout="column">
              <a href="https://www.beinbrech.de/">
                <img src="../assets/beinbrech-logo.jpg" width="300px">
              </a>
              </div>
              </div>
          </div>
    </article>

</div>
