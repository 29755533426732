<footer class="footer">

    <div class ="footer-content"  fxLayout="row"  fxLayout.xs ="column" fxLayoutAlign="center">

        <div fxFlex="33" class="sec1" fxFlex.xs="100">
          Löffler Gerüstbau GmbH & Co. KG <br/>
          Stellestraße 24 <br/>
          72135 Dettenhausen
        </div>

        <br/>

        <div fxFlex="33" class="sec2">
         <b>Telefon: </b>  07157 62906 <br/>
         <b>Telefax:</b> 07157 66183 <br/>
          <b>E-Mail: </b> <a href= "mailto:info@loeffler-dettenhausen.de"> info@loeffler-dettenhausen.de</a><br/>
         <b>Kontaktformular: </b><a routerLink="/kontakt"> Zum Kontaktformular</a>
        </div>

        <br/>

        <div fxFlex="33" class="sec3" fxFlex.xs="100">
           <a routerLink="/impressum"> Impressum </a><br/><br/>
           <a routerLink="/datenschutz">Datenschutzerklärung</a> <br/>
        </div>
      </div>


</footer>
