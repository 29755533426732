
    <header class="hero">
      <div class="center-content ">
        <h1>Willkommen</h1>
        <h3>bei Löffler Gerüstbau</h3>
        <button mat-raised-button color="accent" routerLink="/kontakt">Kontakt</button>
      </div>
    </header>

  <div class="container">
    <article>
      <h1> Löffler Gerüstbau </h1>
      <p>
        Unser Gerüstbaubetrieb ist in Dettenhausen nördlich von Tübingen. Wir führen Gerüstbau
        in unserer Region aus.
      </p>
      <p>
        Mit unsere Team arbeiten wir für private Bauherren und im Bereich Gerüstbau auch mit
         größeren Firmen sowie Fertighaus-Herstellern und Industriefirmen, zusammen.
      </p>
      <p>
        Die persönliche Beratung und die Planungshilfe, auch bei komplexeren Aufgaben, ist unsere Stärke. Daher gibt es bei uns keine festen Preislisten,
        sondern alle Angebote werden individuell, passend zur Situation und den Erfordernissen kalkuliert.
      </p>
      <p>
        Durch langjährige Geschäftsbeziehungen haben wir zuverlässige Lieferanten für qualitative gute Materialien gefunden.
      </p>
      <p>
        Gerne beraten wir sie auch schon im Planungsstadium um eine kostengünstige und qualitativ optimale Lösung für Sie zu finden.
      </p>
    </article>
      <hr>
      <div class="content" fxLayout="row" fxLayout.xs="column" fxFlexFill>

        <div class="sec1 loeffler-card" fxFlexAlign="stretch" fxFlex="100"
        fxFlex.md="100"
        fxFlex.sm="100"
        fxFlex.xs="100"
        fxLayout="column">
          <app-info-card [title]="cardInfo1"></app-info-card>
              </div>

      </div>

  </div>
<!--

  Gerüstbau, Dachfenster Experten, Zimmererarbeiten
  Jobs

-->  <style>
    .hero{
      background-image: url('../assets/loffler-background.jpg');
      background-size: cover;
      background-position: center;
      width: 100wh;
      height: 50vh;
      display: flex;
      background-attachment: fixed;
    }
mat-grid-tile {
      background: lightblue;
    }

    </style>
