    <mat-card class="info-card">
        <mat-card-header>
          <div mat-card-avatar class="example-header-image"></div>
          <mat-card-title>{{cardInfo.title}}</mat-card-title>
          <mat-card-subtitle>{{cardInfo.subTitle}}</mat-card-subtitle>
        </mat-card-header>
        <img mat-card-image [src]="cardInfo.image" alt="cardInfo.title">
        <mat-card-content>
          <p>
           {{cardInfo.text}}
          </p>
        </mat-card-content>
        <mat-card-actions>
          <button mat-raised-button color="accent" [routerLink]= "cardInfo.link" >Mehr</button>
        </mat-card-actions>
      </mat-card>
