<!--
    <mat-toolbar class = "loeffler-toolbar">  
    <mat-toolbar-row >
        <h1> Gerüstbau </h1>
    </mat-toolbar-row>
</mat-toolbar> -->

<div class="banner gerust-banner">
    <div class="center-content">
    </div>
</div>

<div class="container" (window:resize)="onResize($event)">
    <article>
        <h1> Gerüstbau </h1>
        <p>
            Wir bieten Fassaden-, Raum-, Fahr- und Sondergerüste an.
        </p>
        <p>
            Die ca. 12.000 qm Fassadengerüst werden für Fassadenarbeiten, z.B. für Maler- oder Gipserarbeiten, und als Schutzgerüste gegen 
            Absturz bei Rohbauarbeiten von Maurern und Fertighausherstellern verwendet.
        </p>
        <p>
            Außerdem wird ein Fassadengerüst als Arbeits- und Schutzgerüst für Dacharbeiten aller Art verwendet. Dafür haben wir die notwendigen Spezialteile wie 2 m hohe
            Fangschutzgeländer und Ausleger. Selbstverständlich auch Innengeländer und die bei größeren Gebäuden vorgeschriebenen 
            Gerüsttreppen um die Leitergänge ("Durchstiege") zu ersetzen.
        </p>
        <p>
            Die Preise für Fassadengerüste variieren wegen des 
            Arbeitsaufwands für das Anliefern, Aufbauen, Abbauen und Abtransportieren abhängig von den Zufahrtswegen und dem nötigem 
            Materialtransport durch unsere Mitarbeiter zum Aufstellungsort.
        </p>
        <p>
            Ein weiterer Faktor bei der Kalkulation ist die Gebäudeform.
            Stark kleinteilige Fassaden mit Balkonen und Erkern benötigen mehr Aufbauzeit als große grade Flächen.
            Gerne unterbreiten wir Ihnen anhand von Plänen oder einem Vorabaufmaß vor Ort ein individuelles Angebot.
        </p>
        <p>
            Für längere Standzeiten als die Grundstandzeit von 4 bzw. 6 Wochen gibt es feste Preise für eine Standzeitverlängerung pro Woche.
        </p>
        <p>
            Für Raumgerüste, z.B. um Deckenflächen zu erreichen und für Sondergerüste um Produktionsmaschinen herum, 
            Tank- und Siloanlagen wird unser AllroundGerüst® verwendet. Mit dem AllroundGerüst® ist fast alles möglich.
        </p>
        <p>
            Wir haben mehrere Fahrgerüste mit ca. 6 m Standhöhe. Diese verleihen wir regional.
            Auf Wunsch bauen wir diese auch fachgerecht bei Ihnen auf und ab.
        </p>

    </article>
    <h2> Fotogalerie </h2>
    <div fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div *ngFor="let tile of tilesRow1" >
            <div fxFlex="33"  class="tile-image"[ngClass]="{'clickable': !onMobile}"
            (click)="openDialog(tile.link,tile.text,tile.logo,tile.source)"
            fxFlex.md="33"
            fxFlex.sm="50"
            fxFlex.xs="100"
            fxLayout="column">
            <img [src]="tile.foto" width="300px"[alt]="tile.text">
            <p *ngIf="onMobile"> {{tile.text}} © {{tile.source}} </p>
            </div>
        </div>
      </div>

      <div fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div *ngFor="let tile of tilesRow2" >
            <div fxFlex="33"  class="tile-image" [ngClass]="{'clickable': !onMobile}"
            (click)="openDialog(tile.link,tile.text, tile.logo, tile.source)"
            fxFlex.md="33"
            fxFlex.sm="50"
            fxFlex.xs="100"
            fxLayout="column">
            <img [src]="tile.foto" width="300px"[alt]="tile.text">
            <p *ngIf="onMobile"> {{tile.text}} © {{tile.source}} </p>
            </div>
        </div>
      </div>

      <div fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div *ngFor="let tile of tilesRow3" >
            <div fxFlex="33"  class="tile-image"[ngClass]="{'clickable': !onMobile}"
            (click)="openDialog(tile.link,tile.text,tile.logo,tile.source)"
            fxFlex.md="33"
            fxFlex.sm="50"
            fxFlex.xs="100"
            fxLayout="column">
            <img [src]="tile.foto" width="300px" [alt]="tile.text">
            <p *ngIf="onMobile"> {{tile.text}} © {{tile.source}} </p>
            </div>
        </div>
    
      </div>
    <!--
      <mat-grid-list cols="4" rowHeight="200px">
        <mat-grid-tile
            *ngFor="let tile of tiles"
            [colspan]="tile.cols"
            [rowspan]="tile.rows"
            [style.background-image]="tile.foto"
            (click)="openDialog(tile.link)"
            class="tile-image">
        </mat-grid-tile>
      </mat-grid-list>
    -->
    <hr>
    <small>AllroundGerüst® ist ein eingetragene Marke der Firma Wilhelm Layher GmbH & Co KG</small>
</div>