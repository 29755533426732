import { Component, OnInit } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
@Component({
  selector: 'accept-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss']
})
export class CookiesComponent implements OnInit {

  constructor(private _snackBar: MatSnackBar) {
    this.openSnackBar();
  }
  openSnackBar() {
    this._snackBar.open("Wir verwenden Cookies, um Ihnen die bestmögliche Nutzungserfahrung zu bieten. Sie stimmen" +
      " der Nutzung von Cookies und unseren Datenschutzbestimmungen zu. ", "Schließen");
  }
  ngOnInit(): void {
  }

}
