import { Component, OnInit } from '@angular/core';
import { ContactForm } from '../models/contactForm.model';
import { FormGroup, FormBuilder, Validators,FormControl } from '@angular/forms';
import {MailService} from '../mail.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  user: ContactForm = new ContactForm();
  registerForm: FormGroup;
  hide = true;
  captchaResponse;
  mailSentSuccessfull = false;
  mailError = false;
  
  constructor(private formBuilder: FormBuilder, private mailService: MailService) { }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      'name': [this.user.name, [
        Validators.required
      ]],
      'email': [this.user.email, [
        Validators.required,
        Validators.email
      ]],
      'salutation': [this.user.salutation, [
      ]],
      'message' : [this.user.message, [
      ]],
      'recaptchaReactive': new FormControl(null, Validators.required),
      'datenschutz' : new FormControl(false,Validators.requiredTrue)
    });
  }

  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
    console.log(this.user);
    this.captchaResponse= captchaResponse;
  }
  onRegisterSubmit() {
    this.user.name = this.registerForm.value.name;
    this.user.email = this.registerForm.value.email;
    this.user.message = this.registerForm.value.message;
    this.user.salutation = this.registerForm.value.salutation;
    this.user.captcha = this.captchaResponse;
    
    console.log(this.user);
    
    this.mailService.sendMail(this.user).subscribe(
      data => this.onMailSent(),
      error => this.onMailError()
    );
  }

  onMailSent(){
    this.mailSentSuccessfull = true;
    this.mailError = false;
  }

  onMailError(){
    this.mailError = true;
    this.mailSentSuccessfull = false;
  }
}