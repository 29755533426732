<div class="banner holz-banner">
</div>

<div class="container">
    <article>
        <h1> Holzbau </h1>
        <p>
            Wir führen im Holzbau vorwiegend Reparaturen, Umbauten, Dachdämmungen, Holzfassaden und Dachsanierungen aus. 
            Dieses beinhaltet natürlich auch die dafür notwendigen Gerüste, die wir selber mit unseren eigenem Gerüstmaterial und Gerüstbauern 
            aufstellen.
        </p>
        <p>
            Wir führen auch das Decken von Ton- Betonziegeln mit aus. Dies vereinfacht die Koordination für Sie.
        </p>
        <p>
            <a routerLink="/dachfenster">Dachfenster</a>
            gehören auch zum Holzbau.
        </p>
        <p>
            Die Ansprüche an Dächer und damit an den Zimmerer sind durch ausgebaute Dächer und durch die erhöhten Anforderungen an die 
            Wärmedämmung gestiegen.
        </p>
        <p>
            Heute müssen Dächer luftdicht ausgeführt werden. Im Detail muss fachgerecht und wärmebrückenfrei geplant werden.
             Wir haben damit viel Erfahrung und planen die Ausführungs-Detaillösungen durch unseren erfahrenen Zimmermeister.
        </p>
        <p>
            Blower-Door-Tests durch unabhängige Gutachter haben unsere Sorgfalt und Detailplanung bei diesen kritischen Arbeiten mehrfach 
            unter Beweis gestellt.
        </p>
        <p>
            Wir bieten auch K-Wert Berechnungen für Ihre Dachdämmung an, um verschiedene Optionen, auch aus energetischer 
            Sicht zu vergleichen und dann ggf. auf die Ansprüche der KfW-Förderung abzustimmen.
        </p>
        <h2> Fotogalerie </h2>

        <div class="content" fxLayout="row" fxLayout.xs="column" fxFlexFill>
    
            <div class="tile-image"*ngFor="let tile of tilesRow1" >
                <div fxFlex="33" 
                [ngClass]="{'clickable': !onMobile}"
                (click)="openDialog(tile.link,tile.text,tile.title)"
                fxFlex.md="33"
                fxFlex.sm="50"
                fxFlex.xs="100"
                fxLayout="column">
                <img [src]="tile.foto" width="300px"[alt]="tile.text">
                <p *ngIf="onMobile"> {{tile.text}} © {{tile.source}} </p>
            </div>
    
            </div>
        
          </div>
    
          <div class="content" fxLayout="row" fxLayout.xs="column" fxFlexFill>
    
            <div class="tile-image"*ngFor="let tile of tilesRow2" >
                <div fxFlex="33" 
                [ngClass]="{'clickable': !onMobile}"
                (click)="openDialog(tile.link,tile.text,tile.title)"
                fxFlex.md="33"
                fxFlex.sm="50"
                fxFlex.xs="100"
                fxLayout="column">
                <img [src]="tile.foto" width="300px"[alt]="tile.text">
                <p *ngIf="onMobile"> {{tile.text}} © {{tile.source}} </p>
            </div>
    
            </div>
        
          </div>
    
          <div class="content" fxLayout="row" fxLayout.xs="column" fxFlexFill>
    
            <div class="tile-image"*ngFor="let tile of tilesRow3" >
                <div fxFlex="33" 
                [ngClass]="{'clickable': !onMobile}"
                (click)="openDialog(tile.link,tile.text,tile.title)"
                fxFlex.md="33"
                fxFlex.sm="50"
                fxFlex.xs="100"
                fxLayout="column">
                <img [src]="tile.foto" width="300px" [alt]="tile.text">
                <p *ngIf="onMobile"> {{tile.text}} ©Löffler </p>
            </div>
    
            </div>
        
          </div>
    </article>
</div>