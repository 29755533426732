import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog,MAT_DIALOG_DATA} from '@angular/material/dialog'

@Component({
  selector: 'app-dachfenster',
  templateUrl: './dachfenster.component.html',
  styleUrls: ['./dachfenster.component.scss']
})
export class DachfensterComponent implements OnInit {

  tilesRow1: any[] = [
    {source: 'Roto', text: 'Schwingfenster', logo: '../assets/Roto-logo.png', foto: '../assets/dachfenster/roto1-small.jpg',link:'../assets/dachfenster/roto1.jpg'},
    {source: 'Roto', text: 'Klapp-Schwingfenster', logo: '../assets/Roto-logo.png', foto:'../assets/dachfenster/roto2-small.jpg',link:'../assets/dachfenster/roto2.jpg'},
    {source: 'Velux', text: 'Klapp-Schwing-Fenster', logo: '../assets/velux-logo.jpg', foto: '../assets/dachfenster/velux1-small.jpg',link:'../assets/dachfenster/velux1.jpg'},  
  ];
  tilesRow2: any[] = [
    {source: 'Velux', text: 'Dachfensterkombination', logo: '../assets/velux-logo.jpg', foto: '../assets/dachfenster/velux2-small.jpg',link:'../assets/dachfenster/velux2.jpg'},
    {source: '', text: 'Dachfenster', logo: '../assets/logo.jpg', foto: '../assets/dachfenster/loffler1-small.jpg',link:'../assets/dachfenster/loffler1.jpg'},
    {source: '', text: 'Dachfenster', logo: '../assets/logo.jpg', foto: '../assets/dachfenster/loffler2-small.jpg',link:'../assets/dachfenster/loffler2.jpg'},


  ];
  tilesRow3: any[] = [
    {source: '', text: 'Dachfenster', logo: '../assets/logo.jpg', foto: '../assets/dachfenster/loffler3-small.jpg',link:'../assets/dachfenster/loffler3.jpg'},
    {source: '', text: 'Dachfenster', logo: '../assets/logo.jpg', foto: '../assets/dachfenster/loffler4-small.jpg',link:'../assets/dachfenster/loffler4.JPG'},
    {source: '', text: 'Dachfenster', logo: '../assets/logo.jpg', foto: '../assets/dachfenster/loffler5-small.jpg',link:'../assets/dachfenster/loffler5.jpg'}
  ];
  onMobile = false;

  constructor(public dialog: MatDialog) { }

  openDialog(fotopath:String, text:String, logo: String, source: String) {
    if(!this.onMobile){
      this.dialog.open(ImageDialog, {
        data: {
          url: fotopath,
          title: '',
          text: text,
          logo: logo,
          source: source
        }
      });
    }
  }

  ngOnInit(): void {
    this.onMobile = (window.innerWidth <= 450) ? true : false;
  }

  onResize(event) {
    this.onMobile = (event.target.innerWidth <= 450) ? true : false;
    console.log(window.innerWidth);
  }
}

@Component({
  selector: 'image-dialog',
  templateUrl: 'image-dialog.html',
})
export class ImageDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data) {
    console.log(data.url)
  }
}
