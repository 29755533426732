<div mat-dialog-content>
    <h2> {{data.title}} </h2>
    <img  class="fullsize-image" [src]="data.url">
    <p> {{data.text}} </p>
    <p> Quelle: Löffler </p> 
</div>

<style>
    .fullsize-image{
        max-height: 80vh;
}
</style>

<script>
    console.log(data);
</script>