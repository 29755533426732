import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {HttpHeaders} from '@angular/common/http';
import { Observable } from  'rxjs';
import { ContactForm } from './models/contactForm.model';

@Injectable({
  providedIn: 'root'
})

export class MailService {

  url = "mail.php";

  constructor(private httpClient: HttpClient) {}

  sendMail(form: ContactForm ){
    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/x-www-form-urlencoded ',
      'Cache-Control': 'no-cache'
         });    
         
    let options = {
      headers: httpHeaders
         };     

    console.log("logging from mail service :");
    console.log(form);
   return  this.httpClient.post<ContactForm>(this.url,form,options);
  }
}
