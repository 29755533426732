import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatButtonModule} from '@angular/material/button';
import { MainNavComponent } from './main-nav/main-nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatCardModule} from '@angular/material/card';
import {MatGridListModule} from '@angular/material/grid-list';
import { FlexLayoutModule } from '@angular/flex-layout';
import { InfoCardComponent } from './info-card/info-card.component';
import {AboutComponent} from './about/about.component';
import {ContactComponent} from './contact/contact.component';
import { HomeComponent } from './home/home.component';
import { CookiesComponent } from './cookies/cookies.component';
import {MatFormFieldModule} from  '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import { FooterComponent } from './footer/footer.component';
import {FormsModule,ReactiveFormsModule} from '@angular/forms';
import { RecaptchaModule,RecaptchaFormsModule  } from 'ng-recaptcha';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {JobComponent} from './job/job.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { DatenschutzComponent } from './datenschutz/datenschutz.component';
import { GerustbauComponent } from './gerustbau/gerustbau.component';
import { DachfensterComponent } from './dachfenster/dachfenster.component';
import { HolzbauComponent } from './holzbau/holzbau.component';
import {MatDialogModule,MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatTreeModule} from '@angular/material/tree';
import { HttpClientModule } from '@angular/common/http';
import { RECAPTCHA_LANGUAGE } from 'ng-recaptcha';
import {MatSnackBarModule} from '@angular/material/snack-bar';


const appRoutes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'uber-uns', component: AboutComponent},
  {path: 'kontakt', component: ContactComponent},
  {path: 'jobs', component: JobComponent},
  {path: 'impressum', component: ImpressumComponent},
  {path: 'datenschutz', component: DatenschutzComponent},
  {path: 'gerustbau', component: GerustbauComponent},
  {path:'dachfenster', component: DachfensterComponent},
  {path: 'holzbau', component: HolzbauComponent}
]
@NgModule({
  declarations: [
    AppComponent,
    MainNavComponent,
    InfoCardComponent,
    HomeComponent,
    AboutComponent,
    ContactComponent,
    FooterComponent,
    JobComponent,
    ImpressumComponent,
    DatenschutzComponent,
    GerustbauComponent,
    DachfensterComponent,
    HolzbauComponent,
    CookiesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    LayoutModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatCardModule,
    MatGridListModule,
    FlexLayoutModule,
    RouterModule.forRoot(appRoutes, {
      enableTracing: true,
      scrollPositionRestoration: 'top'
   }),
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    MatCheckboxModule,
    MatDialogModule,
    MatTreeModule,
    HttpClientModule,
    MatSnackBarModule

  ],
  providers: [
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: 'de', // use Deutsch language
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
