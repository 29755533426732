

  <div class="container">
	<div class ="loeffler-contact">
		<h1> Kontakt </h1>
		<h3> Postanschrift </h3>
		<address>
			Löffler Gerüstbau GmbH & Co. KG<br>
			Stellestraße 24 <br>
			72135 Dettenhausen <br>
		</address>
		<hr>
		<h3> Bürozeiten </h3>
		<p> Mo bis Fr	08:30 - 12:30 Uhr und 13:30 - 16:00 Uhr	<br>
		</p>
		<hr>
		<h3> Telefon</h3>
		<a href="tel:07157 62906">07157 62906</a>
		<hr>
		<h3> Kontaktanfrage</h3>
		<p>
			Bitte nutzen Sie vorzugsweise unser Kontaktformular um mit uns in Kontakt zu treten.

		</p>
		<form class="loeffler-form" [formGroup]="registerForm" (ngSubmit)="registerForm.valid && onRegisterSubmit()" novalidate>
			<div class="loeffler-contact-form">
				<mat-form-field class ="loeffler-form-item">
					<mat-label> Anrede</mat-label>
				  <mat-select id ="anrede" formControlName="salutation" >
					  <mat-option value="Herr">Herr</mat-option>
					  <mat-option value="Frau">Frau</mat-option>
				  </mat-select>
				  </mat-form-field>
				  <br>


			  <mat-form-field class ="loeffler-form-item">
				<input id="name"
					   matInput
					   formControlName="name"
					   placeholder="Ihre Name" />
			  </mat-form-field>

			  <mat-form-field class ="loeffler-form-item">
				  <input id="email"
						 matInput
						 formControlName="email"
						 placeholder="Ihre E-Mail" />

					 <mat-error *ngIf="registerForm.controls.email.hasError('email') && !registerForm.controls.email.hasError('required')">
						 Bitte geben Sie eine gültige E-Mail-Adresse ein
					 </mat-error>
					 <mat-error *ngIf="registerForm.controls.email.hasError('required')">
					 E-Mail ist <strong>erforderlich</strong>
					 </mat-error>
				</mat-form-field>
				<br>
				<mat-form-field class="loeffler-form-item">
					<mat-label>Nachricht</mat-label>
      				<textarea formControlName="message" matInput placeholder="Bitte schreiben Sie Ihre Nachricht hier"></textarea>
    			</mat-form-field>
				<br>

				<div class="loeffler-form-item">
					<h4>Einwilligung zur Datenspeicherung.</h4>
					<p class="datenschutz">
						Die in diesem Formular von Ihnen eingegeben Daten, speichern und verwenden wir zur Kontaktaufnahme. Sie können der Einwilligung jederzeit widersprechen.
						<br>
						Näheres zum Thema Datenschutz finden Sie <a routerLink="/datenschutz"> hier in unserer Datenschutzerklärung</a>.
					</p>
					<mat-checkbox formControlName="datenschutz">Ich habe die Datenschutzbestimmungen gelesen und akzeptiere diese.</mat-checkbox>

				</div>

				<div class="loeffler-form-item">
					<re-captcha formControlName="recaptchaReactive" required (resolved)="resolved($event)" siteKey="6LfC7fsUAAAAADI9qlZjqsOEOrukYa6SbIv47BNl"></re-captcha>
				</div>

				<br>

				<div class="loeffler-form-item">
					<button mat-button type="submit"
					class="mat-raised-button mat-primary"
					[disabled]="!registerForm.valid">Absenden</button>
				</div>
				<div>
					<p *ngIf="mailSentSuccessfull"> Vielen Dank für Ihre Nachricht. Wir werden uns schnellest möglich mit Ihnen in Verbindung setzen. </p>
					<p style="color:red" *ngIf="mailError"> Nachricht konnte nicht gesendet werden. Bitte versuchen Sie es erneut. </p>
				</div>

				</div>
		  </form>
	</div>
</div>

<style>

</style>
