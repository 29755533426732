<mat-toolbar class = "loeffler-toolbar">  
    <mat-toolbar-row >
        <h1> Datenschutzerklärung </h1>
    </mat-toolbar-row>
</mat-toolbar>
<div class="container">
    <article>
        <p> Wir freuen uns über Ihr Interesse an unserer Website. Der Schutz Ihrer personenbezogenen Daten bei der Erhebung, Verarbeitung und Nutzung anlässlich Ihres Besuchs auf unserer Website ist uns ein wichtiges Anliegen. </p>
        
        <h2> 1. Personenbezogene Daten </h2>
        
        <p> Personenbezogene Daten sind Informationen, die dazu genutzt werden können, Ihre Identität zu erfahren. Darunter fallen Ihr Name, die Adresse, Postanschrift und Telefonnummer. Informationen, die nicht mit Ihrer Identität in Verbindung gebracht werden (wie zum Beispiel Anzahl der Nutzer einer Internetseite), gehören nicht dazu. Sie können unser Online-Angebot grundsätzlich ohne Offenlegung Ihrer Identität nutzen.<br />
        <br />
        Wenn Sie unsere Website besuchen oder unsere Dienste nutzen übermittelt das Gerät, mit dem Sie die Seite aufrufen, automatisch Log-Daten (Verbindungsdaten) an unsere Server. Log-Daten enthalten die IP-Adresse des Gerätes, mit dem Sie auf die Website oder einen Dienst zugreifen, die Art des Browsers, mit dem Sie zugreifen, die Seite, die Sie zuvor besucht haben, Ihre Systemkonfiguration sowie Datum und Zeitangaben. Die IP-Adressen werden nur gespeichert soweit es zur Erbringung unserer Dienste erforderlich ist. Ansonsten werden die IP-Adressen gel&ouml;scht oder anonymisiert. Ihre IP-Adresse beim Besuch unserer Website speichern wir zur Erkennung und Abwehr von Angriffen maximal 7 Tage.<br />
        <br />
        Wenn Sie Informationen anfordern oder andere Anfragen stellen, fragen wir Sie nach Ihrem Namen und anderen persönlichen Informationen. Es unterliegt Ihrer freien Entscheidung, ob Sie diese Daten eingeben. Ihre Angaben speichern wir auf besonders geschützten Servern in der Deutschland. Der Zugriff darauf ist nur wenigen, befugten Personen m&ouml;glich. Diese sind f&uuml;r die technische, kaufm&auml;nnische oder redaktionelle Betreuung der Inhalte und Server zust&auml;ndig.<br />
        <br />
        Soweit die Nutzungs- beziehungsweise (bzw) Verkehrsdaten f&uuml;r unsere Dienste erforderlich sind werden sie l&auml;ngstens bis zu sechs Monate nach Erhebung der Daten gespeichert. Werden die Daten zur Erfüllung bestehender gesetzlicher, satzungsm&auml;&szlig;iger oder vertraglicher Aufbewahrungsfristen ben&ouml;tigt (z.B. Rechnungen), werden diese Daten auch dar&uuml;ber hinaus gespeichert. Diese Daten sind jedoch gesperrt und nur wenigen, befugten Personen &uuml;ber Passw&ouml;rter zug&auml;nglich.  </p>
        
        <h2> Widerspruchsrecht gegen die Datenerhebung in besonderen F&auml;llen sowie gegen Direktwerbung (Art. 21 DSGVO)</h2>
        
        <p> Wenn die Datenverarbeitung auf Grundlage von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, haben Sie jederzeit das Recht, aus Gr&uuml;nden, die sich aus Ihrer besonderen Situation ergeben, gegen die Verarbeitung Ihrer personenbezogenen Daten Widerspruch einzulegen; dies gilt auch f&uuml;r ein auf diese Bestimmungen gest&uuml;tztes Profiling. Die jeweilige Rechtsgrundlage, auf denen eine Verarbeitung beruht, entnehmen Sie dieser Datenschutzerkl&auml;rung. Wenn Sie Widerspruch einlegen, werden wir Ihre betroffenen personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir k&ouml;nnen zwingende schutzw&uuml;rdige Gr&uuml;nde f&uuml;r die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten &uuml;berwiegen oder die Verarbeitung dient der Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen (Widerspruch nach Art. 21 Abs. 1 DSGVO).<br />
        <br />
        Werden Ihre personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, so haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung Sie betreffender personenbezogener Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch f&uuml;r das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht. Wenn Sie widersprechen, werden Ihre personenbezogenen Daten anschlie&szlig;end nicht mehr zum Zwecke der Direktwerbung verwendet (Widerspruch nach Art. 21 Abs. 2 DSGVO).  </p>
        
        <h3> Recht auf Einschr&auml;nkung der Verarbeitung </h3>
        
        <p> Sie haben das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Hierzu k&ouml;nnen Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.  </p>
        &nbsp;
        
        <p> Das Recht auf Einschr&auml;nkung der Verarbeitung besteht in folgenden F&auml;llen:  </p>
        
        <ul>
            <li> Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, ben&ouml;tigen wir in der Regel Zeit, um dies zu &uuml;berpr&uuml;fen. F&uuml;r die Dauer der Pr&uuml;fung haben Sie das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. </li>
            <li> Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtm&auml;&szlig;ig geschah / geschieht, k&ouml;nnen Sie statt der L&ouml;schung die Einschr&auml;nkung der Datenverarbeitung verlangen. </li>
            <li> Wenn wir Ihre personenbezogenen Daten nicht mehr ben&ouml;tigen, Sie sie jedoch zur Aus&uuml;bung, Verteidigung oder Geltendmachung von Rechtsanspr&uuml;chen ben&ouml;tigen, haben Sie das Recht, statt der L&ouml;schung die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.  </li>
            <li> Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abw&auml;gung zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen &uuml;berwiegen, haben Sie das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. </li>
        </ul>
        
        <p> Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschr&auml;nkt haben, d&uuml;rfen diese Daten &ndash; von ihrer Speicherung abgesehen &ndash; nur mit Ihrer Einwilligung oder zur Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen oder zum Schutz der Rechte einer anderen nat&uuml;rlichen oder juristischen Person oder aus Gr&uuml;nden eines wichtigen &ouml;ffentlichen Interesses der Europ&auml;ischen Union oder eines Mitgliedstaats verarbeitet werden.  </p>
        
        <h2> 2. Weitergabe personenbezogener Daten an Dritte </h2>
        
        <p> Wir verwenden Ihre personenbezogenen Informationen nur zum Zweck der Verwendung durch uns. Wir geben die Daten nicht ohne Ihre ausdr&uuml;ckliche Einwilligung an Dritte weiter. Soweit wir gesetzlich oder per Gerichtsbeschluss dazu verpflichtet sind, werden wir Ihre Daten an auskunftsberechtigte Stellen &uuml;bermitteln. </p>
        
        <h2> 3. Minderj&auml;hrigenschutz </h2>
        
        <p> Kinder und Personen unter 18 Jahren sollten ohne Zustimmung der Eltern oder Erziehungsberechtigten keine personenbezogenen Daten an uns &uuml;bermitteln.  </p>
        
        <h2> 4. Links zu anderen Websites </h2>
        
        <p> Unser Online-Angebot enth&auml;lt Links zu anderen Websites. Wir haben keinen Einfluss darauf, dass deren Betreiber die Datenschutzbestimmungen einhalten.  </p>
        

        <h2> 5. Kontakt </h2>
        
        <p> <b>Firmenname: </b>	Löffler Holz- und Gerüstbau GmbH Co. KG </p>
        <p>Stellestr. 24 </p>
        <p>72135 Dettenhausen </p>
        <p> Mehr Kontaktinformationen finden Sie <a routerLink="/kontakt">hier.</a></p>
        
        <h3> Ihre Betroffenenrechte </h3>
        
        <p> Unter den angegebenen Kontaktdaten k&ouml;nnen Sie jederzeit folgende Rechte aus&uuml;ben: </p>
        
        <ul>
            <li> Auskunft &uuml;ber Ihre bei uns gespeicherten Daten und deren Verarbeitung, </li>
            <li> Berichtigung unrichtiger personenbezogener Daten, </li>
            <li> L&ouml;schung Ihrer bei uns gespeicherten Daten, </li>
            <li> Einschr&auml;nkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher Pflichten noch nicht l&ouml;schen d&uuml;rfen, </li>
            <li> Widerspruch gegen die Verarbeitung Ihrer Daten bei uns und </li>
            <li> Daten&uuml;bertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen Vertrag mit uns abgeschlossen haben. </li>
        </ul>
        
        <p> Sofern Sie uns eine Einwilligung erteilt haben, k&ouml;nnen Sie diese jederzeit mit Wirkung f&uuml;r die Zukunft widerrufen. </p>
        
        <p> Sie k&ouml;nnen sich jederzeit mit einer Beschwerde an die f&uuml;r Sie zust&auml;ndige Aufsichtsbeh&ouml;rde wenden. Ihre zust&auml;ndige Aufsichtsbeh&ouml;rde richtet sich nach dem Bundesland oder Kantons Ihres Wohnsitzes, Ihrer Arbeit oder der mutma&szlig;lichen Verletzung. Eine Liste der Aufsichtsbeh&ouml;rden mit Anschrift f&uuml;r Deutschland (f&uuml;r den nicht&ouml;ffentlichen Bereich) und Schweiz und &Ouml;sterreich (Europ&auml;ische Datenschutzbeauftragte) finden Sie unter: </p>
        
        <p> <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html" target="_blank">www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html.</a> </p>
        
        
    </article>
</div>