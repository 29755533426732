import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog,MAT_DIALOG_DATA} from '@angular/material/dialog'
import { title } from 'process';

@Component({
  selector: 'app-holzbau',
  templateUrl: './holzbau.component.html',
  styleUrls: ['./holzbau.component.scss']
})
export class HolzbauComponent implements OnInit {
  tilesRow1: any[] = [
    {title: 'Brücke', text: 'Brücke über Aich', foto: '../assets/holzbau/holz1-small.jpg',link:'../assets/holzbau/holz1.jpg'},
    {title: 'Dachkonstruktion', text: 'Dachkonstruktion aus getrocknetem Holz', foto: '../assets/holzbau/holz2-small.jpg',link:'../assets/holzbau/holz2.jpg'},
    {title: 'Holzrahmen', text: 'Holzrahmen Doppelhaus Dettenhausen',  foto: '../assets/holzbau/holz3-small.jpg',link:'../assets/holzbau/holz3.jpg'}
  ];
  tilesRow2: any[] = [
    {title: 'Holzrahmenwände', text: 'Holzrahmenwände Kindergarten Dettenhausen', foto: '../assets/holzbau/holz4-small.jpg',link:'../assets/holzbau/holz4.JPG'},
    { title: 'Holzfassade', text: 'Sibirische Lärche Holzfassade mit Dämmung',   foto: '../assets/holzbau/holz5-small.jpg',link:'../assets/holzbau/holz5.jpg'},
    {title: 'Terrassenbelag', text: 'Holzterrassenboden',  foto: '../assets/holzbau/holz6-small.jpg',link:'../assets/holzbau/holz6.jpg'}
  ];
  tilesRow3: any[] = [
    { title: 'Trespa-Fassadenplatten', text: 'Trespa-Fassadenplatten - nie wieder streichen',  foto: '../assets/holzbau/holz7-small.jpg',link:'../assets/holzbau/holz7.JPG'},
    { title: 'Vordachs', text: 'Untersicht eines Vordachs',  foto: '../assets/holzbau/holz8-small.jpg',link:'../assets/holzbau/holz8.jpg'},
    { title: 'Zimmermann', text: 'Traditioneller Zimmermann',  foto: '../assets/holzbau/holz9-small.jpg',link:'../assets/holzbau/holz9.JPG'},
  ];

  onMobile = false;

  constructor(public dialog: MatDialog) { }

  openDialog(fotopath:String, text:String, title: String) {
    if(!this.onMobile){
      this.dialog.open(ImageDialog, {
        data: {
          url: fotopath,
          title: title,
          text: text,
          logo: '',
          source: ''
        }
      });
    }
  }

  ngOnInit(): void {
    this.onMobile = (window.innerWidth <= 450) ? true : false;
  }

  onResize(event) {
    this.onMobile = (event.target.innerWidth <= 450) ? true : false;
    console.log(window.innerWidth);
  }
}

@Component({
  selector: 'image-dialog',
  templateUrl: 'image-dialog.html',
})
export class ImageDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data) {
    console.log(data.url)
  }
}
