import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog,MAT_DIALOG_DATA} from '@angular/material/dialog'


@Component({
  selector: 'app-gerustbau',
  templateUrl: './gerustbau.component.html',
  styleUrls: ['./gerustbau.component.scss']
})
export class GerustbauComponent implements OnInit {

  tilesRow1: any[] = [
    {source: 'Löffler', text: 'Gerüstbau', logo: '../assets/loeffler-logo.png', foto: '../assets/gerustbau/gerust1-small.jpg',link:'../assets/gerustbau/gerust1.jpg'},
    {source: 'Löffler', text: 'Gerüstbau', logo: '../assets/loeffler-logo.png', foto: '../assets/gerustbau/gerust2-small.jpg',link:'../assets/gerustbau/gerust2.jpg'},
    {source: 'Löffler', text: 'Gerüstbau', logo: '../assets/loeffler-logo.png', foto:'../assets/gerustbau/gerust3-small.jpg',link:'../assets/gerustbau/gerust3.jpg'},
    ];
  tilesRow2: any[] = [
    {source: 'Löffler', text: 'Gerüstbau', logo: '../assets/loeffler-logo.png', foto: '../assets/gerustbau/gerust4-small.jpg',link:'../assets/gerustbau/gerust4.JPG'},
    {source: 'Löffler', text: 'Gerüstbau', logo: '../assets/loeffler-logo.jpg', foto: '../assets/gerustbau/gerust5-small.jpg',link:'../assets/gerustbau/gerust5.jpg'},
    {source: 'Löffler', text: 'Gerüstbau', logo: '../assets/loeffler-logo.jpg', foto: '../assets/gerustbau/gerust7-small.jpg',link:'../assets/gerustbau/gerust7.jpg'},
  ];
  tilesRow3: any[] = [
    {source: 'Löffler', text: 'Gerüstbau', logo: '../assets/loeffler-logo.jpg', foto: '../assets/gerustbau/gerust8-small.jpg',link:'../assets/gerustbau/gerust8.jpg'},
    {source: 'Löffler', text: 'Gerüstbau', logo: '../assets/loeffler-logo.jpg', foto: '../assets/gerustbau/gerust6-small.jpg',link:'../assets/gerustbau/gerust6.jpg'},
    {source: 'Löffler', text: 'Gerüstbau', logo: '../assets/loeffler-logo.jpg', foto: '../assets/gerustbau/gerust9-small.jpg',link:'../assets/gerustbau/gerust9.jpg'},

  ];
  onMobile = false

  constructor(public dialog: MatDialog) { }
  openDialog(fotopath:String, text:String, logo: String, source: String) {
    if(!this.onMobile){
      this.dialog.open(ImageDialog, {
        data: {
          url: fotopath,
          source: source,
          text: text
  
        }
      });
    }

  }

  ngOnInit(): void {
    this.onMobile = (window.innerWidth <= 450) ? true : false;
  }

  onResize(event) {
    this.onMobile = (event.target.innerWidth <= 450) ? true : false;
    console.log(window.innerWidth);
  }

}

@Component({
  selector: 'image-dialog',
  templateUrl: 'image-dialog.html',
})
export class ImageDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data) {
    console.log(data.url)
  }
}