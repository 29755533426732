<h2> {{data.text}}</h2>
<div mat-dialog-content>
<img class="fullsize-image" [src]="data.url">
<p> Quelle: © {{data.source}} </p>
</div>

<style>
    .fullsize-image{
        max-height: 80vh;
}
</style>