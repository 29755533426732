<!-- 
    <mat-toolbar class = "loeffler-toolbar">  
    <mat-toolbar-row >
        <h1> Dachfenster </h1>
    </mat-toolbar-row>
</mat-toolbar> -->

<div class="banner dachfenster-banner">
    <p class="banner-text">  ©Roto </p>
</div>

<div class="container" (window:resize)="onResize($event)">
    <article>
        <h1> Dachfenster </h1>
        <p>
            Die Dachfenster der beiden Markführer Velux und Roto bauen wir im Neubau und im Bestand ein.
            Sie ermöglichen die Belichtung von Dachräumen und sind eine kostengünstige Alternative zu genehmigungspflichtigen Gauben.        </p>
        <p>
            Heute werden die meisten Fenster aus weißen Kunststoffrahmen hergestellt, da man diese nicht nachstreichen muss.
            Ebenso werden die Verkleidungen des Dachquerschnitts innen unter dem Dachfenster mit weißen Kunststoff-Innenfuttern 
            hell und pflegeleicht verkleidet.
        </p>
        <p>
            Grundsätzlich gibt es zwei Arten wie die Fenstermechanik zum Öffnen funktioniert:
        </p>
        <p> 
            Zum einen gibt es Schwingflügelfenster. Diese haben um die Querachse schwenkbare Fensterflügel. 
            Wenn man die Flügel ganz durch schwenkt kann man die Außenscheiben leicht von innen reinigen. 
            Da aber der Fensterflügel beim Schwenken in der oberen Hälfte nach unten geht, ist sind diese nicht geeignet für Stellen 
            an denen die Kopfhöhe benötigt wird.
        </p>
        <p>
            Dafür eignen sich Klapp-/Schwing-Flügeldachfenster: 
            Diese lassen sich zum Putzen wie zuvor beschrieben öffnen, werden aber ansonsten unten nach außen geklappt, so dass der Fensterflügel sich oben nicht bewegt. 
            Diese Fenster haben starke Federn, die bei der Klapp-Öffnung das Gewicht der Doppel- oder Dreifachverglasung halten, damit das Dachfenster offen stehen bleiben kann. 
            Diese Dachfenster sind daher auch etwas teurer.
        </p>
        <p>
            Für Dachfenster gibt es von den Herstellern Zubehör wie elektrisch oder per Solarpanel angetriebene Rollläden, 
            manuelle Verdunklungsrollos und vieles mehr. Gerne beraten wir Sie und zeigen Ihnen die große Auswahl an Dekoren für Rollos, 
            Jalousien und Faltstores.
        </p>
        <p>
            Wir führen auch den Austausch alter Dachfenster gegen neue aus. Dies dauert in der Regel ein bis zwei Tage. 
            Durch unsere Arbeitsweise, einem Schutz des Bodens und der Möbel, ist der Austausch ohne viel Verschmutzung und stressfrei für Sie möglich. 
            Gerne bieten wir Ihnen den Austausch Ihrer alten Dachfenster zum Festpreis an.
        </p> 
    </article>
    <h2> Fotogalerie </h2>

    <div class="content" fxLayout="row" fxLayout.xs="column" fxFlexFill>

        <div class="tile-image"*ngFor="let tile of tilesRow1" >
            <div fxFlex="33" 
            [ngClass]="{'clickable': !onMobile}"
            (click)="openDialog(tile.link,tile.text,tile.logo,tile.source)"
            fxFlex.md="33"
            fxFlex.sm="50"
            fxFlex.xs="100"
            fxLayout="column">
            <img [src]="tile.foto" width="300px"[alt]="tile.text">
            <p *ngIf="onMobile && tile.source=='';else fullText"> {{tile.text}} ©Löffler</p>
            <ng-template #fullText>
               <p  *ngIf="onMobile">
                {{tile.text}} © {{tile.source}}
               </p> 
              </ng-template>
         </div>

        </div>
    
      </div>

      <div class="content" fxLayout="row" fxLayout.xs="column" fxFlexFill>

        <div class="tile-image"*ngFor="let tile of tilesRow2" >
            <div fxFlex="33" 
            [ngClass]="{'clickable': !onMobile}"
            (click)="openDialog(tile.link,tile.text, tile.logo, tile.source)"
            fxFlex.md="33"
            fxFlex.sm="50"
            fxFlex.xs="100"
            fxLayout="column">
            <img [src]="tile.foto" width="300px"[alt]="tile.text">
            <p *ngIf="onMobile && tile.source=='';else fullText"> {{tile.text}} ©Löffler</p>
            <ng-template #fullText>
               <p  *ngIf="onMobile">
                {{tile.text}} © {{tile.source}}
               </p> 
              </ng-template>
         </div>

        </div>
    
      </div>

      <div class="content" fxLayout="row" fxLayout.xs="column" fxFlexFill>

        <div class="tile-image"*ngFor="let tile of tilesRow3" >
            <div fxFlex="33" 
            [ngClass]="{'clickable': !onMobile}"
            (click)="openDialog(tile.link,tile.text,tile.logo,tile.source)"
            fxFlex.md="33"
            fxFlex.sm="50"
            fxFlex.xs="100"
            fxLayout="column">
            <img [src]="tile.foto" width="300px" [alt]="tile.text">
            <p *ngIf="onMobile && tile.source=='';else fullText"> {{tile.text}} ©Löffler</p>
            <ng-template #fullText>
               <p  *ngIf="onMobile">
                {{tile.text}} © {{tile.source}}
               </p> 
              </ng-template>
         </div>

        </div>
    
      </div>
      <!--

      <mat-grid-list [cols]="breakpoint" rowHeight="200px" (window:resize)="onResize($event)">
        <mat-grid-tile
            *ngFor="let tile of tiles"
            [style.background-image]="tile.foto"
            (click)="openDialog(tile.link,tile.text)"
            class="tile-image">
        </mat-grid-tile>
      </mat-grid-list>
      -->
</div>