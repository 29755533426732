<div mat-dialog-content>
    <h2> {{data.source}} {{data.text}} </h2>
    <img  class="fullsize-image" [src]="data.url">
    <p> Quelle: <img [src] ="data.logo" width="50px"> </p> 
</div>

<style>
    .fullsize-image{
        max-height: 80vh;
}
</style>