<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" [ngClass]="{hidden: (isHandset$ | async) === false}" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false">
    <mat-toolbar>
      <mat-toolbar-row class="main-color-background">
     <a class="sidenav-homelink" routerLink="/">Löffler</a>
      </mat-toolbar-row>
      </mat-toolbar>
    <mat-nav-list>
      <a mat-list-item  routerLinkActive="active-link" routerLink="/uber-uns">Über Uns</a>
      <a mat-list-item  routerLinkActive="active-link"  routerLink="/gerustbau">Gerüstbau</a>
      <a mat-list-item  routerLinkActive="active-link" routerLink="/kontakt">Kontakt</a>
      <a mat-list-item  routerLinkActive="active-link" routerLink="/jobs">Karriere</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary" class="main-toolbar">
        <span class="home-link" [ngClass]="{'home-link-mobile': (isHandset$ | async) === true}">
          <a routerLink="/"><img class="logo" alt="zu Startseite" src="../assets/logo.jpg" /> </a>
        </span>
        <span [ngClass]="{'spacer-mobile': (isHandset$ | async) === true}"></span>

        <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>

      <span class="spacer"> </span>

      <div  [ngClass]="{hidden: (isHandset$ | async) === true}">
        <mat-nav-list>
          <a mat-list-item  routerLinkActive="active-link" routerLink="/uber-uns">Über Uns</a>
          <a mat-list-item  routerLinkActive="active-link" routerLink="/gerustbau">Gerüstbau</a>
          <a mat-list-item  routerLinkActive="active-link" routerLink="/kontakt">Kontakt</a>
          <a mat-list-item  routerLinkActive="active-link" routerLink="/jobs">Karriere</a>
        </mat-nav-list>
      </div>
    </mat-toolbar>
    <!-- Add Content Here -->
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
